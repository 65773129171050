<template>
  <div id="mian">
    <div class="header">
      <span @click="$router.push('/bill_collect')">交易账单</span>
      <span id="fzcolor">分账账单</span>
    </div>
    <!-- 上面表单框 -->
    <div class="con_from">
      <div class="lineP">
        <div class="item">
          <i>商户名称</i>
          <el-input
            v-model="query.keyword"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="商户名称/编号"
          ></el-input>
        </div>
        <div class="item itemTime">
          <i>账单日期</i>
          <el-date-picker
            v-model="query.startDate"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择时间"
          ></el-date-picker>
          <span style="padding-left: 10px; padding-right: 10px">-</span>
          <el-date-picker
            v-model="query.endDate"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择时间"
          ></el-date-picker>
        </div>
        <div class="itembtn">
          <el-button class="searchBt" @click="getSummaryData">查询</el-button>
          <el-button @click="outputShow" class="outputBt">导出</el-button>
        </div>
      </div>
      <p class="desc">
        <i class="el-icon-warning"></i>
        <span>该统计数据以机构账单数据为依据</span>
      </p>
      <div class="order">
        <span class="labels">平台商户交易入账</span>
        <span>交易笔数：{{ statistics.pltTradeNum }}笔</span>
        <i>|</i>
        <span
          >分账金额：{{
            (statistics.pltTradeAmount / 100) | formatMoney
          }}元</span
        >
        <i>|</i>
        <span
          >手续费：{{ (statistics.pltTradeFee / 100) | formatMoney }}元</span
        >
        <i>|</i>
        <span
          >入账金额：{{
            (statistics.pltReceiptAmount / 100) | formatMoney
          }}元</span
        >
      </div>
      <div class="order">
        <span class="labels">平台商户退款扣款</span>
        <span>退款笔数：{{ statistics.pltRefundNum }}笔</span>
        <i>|</i>
        <span
          >退款金额：{{
            (statistics.pltRefundAmount / 100) | formatMoney
          }}元</span
        >
        <i>|</i>
        <span
          >手续费回退：{{
            (statistics.pltRefundFee / 100) | formatMoney
          }}元</span
        >
        <i>|</i>
        <span
          >扣款金额：{{
            (statistics.pltRefundDeductAmount / 100) | formatMoney
          }}元</span
        >
      </div>
      <div class="order">
        <span class="labels">分账商户交易入账</span>
        <span>交易笔数：{{ statistics.tradeNum }}笔</span>
        <i>|</i>
        <span
          >分账金额：{{ (statistics.tradeAmount / 100) | formatMoney }}元</span
        >
        <i>|</i>
        <span>手续费：{{ (statistics.tradeFee / 100) | formatMoney }}元</span>
        <i>|</i>
        <span
          >入账金额：{{
            (statistics.receiptAmount / 100) | formatMoney
          }}元</span
        >
      </div>
      <div class="order">
        <span class="labels">分账商户退款扣款</span>
        <span
          >退款笔数：{{
            statistics.refundNum ? statistics.refundNum : 0
          }}笔</span
        >
        <i>|</i>
        <span
          >退款金额：{{ (statistics.refundAmount / 100) | formatMoney }}元</span
        >
        <i>|</i>
        <span
          >手续费回退：{{ (statistics.refundFee / 100) | formatMoney }}元</span
        >
        <i>|</i>
        <span
          >扣款金额：{{
            (statistics.refundDeductAmount / 100) | formatMoney
          }}元</span
        >
      </div>
      <div class="order">
        <span class="labels">分账商户出款扣款</span>
        <span>出款笔数：{{ statistics.settNum }}笔</span>
        <i>|</i>
        <span
          >出款金额：{{ (statistics.settAmount / 100) | formatMoney }}元</span
        >
        <i>|</i>
        <span>手续费：{{ (statistics.settFee / 100) | formatMoney }}元</span>
        <i>|</i>
        <span
          >扣款金额：{{
            (statistics.settDeductAmount / 100) | formatMoney
          }}元</span
        >
      </div>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td>
            <p>账单日期</p>
          </td>
          <td>
            <p>商户名称</p>
          </td>
          <td>
            <p>商户编号</p>
          </td>
          <td>
            <p>分账角色</p>
          </td>
          <td>
            <p>交易入账</p>
          </td>
          <td>
            <p>退款扣款</p>
          </td>
          <td>
            <p>出款扣款</p>
          </td>
        </tr>
        <tr v-for="(v, i) in tabData" :key="i">
          <td :rowspan="2" v-if="i % 2 == 0">
            <p>{{ v.billDate }}</p>
          </td>
          <td :rowspan="2" v-if="i % 2 == 0">
            <p>{{ v.mchName }}</p>
          </td>
          <td :rowspan="2" v-if="i % 2 == 0">
            <p>{{ v.mchId }}</p>
          </td>
          <td v-if="i % 2 == 0">
            <p>平台商户</p>
          </td>
          <td class="showMoney" v-if="i % 2 == 0">
            <p style="cursor: pointer">
              {{ (v.plt.pltReceiptAmount / 100) | formatMoney }}
              <i class="el-icon-caret-bottom"></i>
            </p>
            <div
              class="show"
              :class="
                i >= tabData.length - 2 && tabData.length > 8
                  ? 'show-top'
                  : 'show-bottom'
              "
            >
              <div>交易笔数：{{ v.plt.pltTradeNum }}</div>
              <div>
                分账金额：{{ (v.plt.pltTradeAmount / 100) | formatMoney }}
              </div>
              <div>手续费：{{ (v.plt.pltTradeFee / 100) | formatMoney }}</div>
              <div>
                入账金额：{{ (v.plt.pltReceiptAmount / 100) | formatMoney }}
              </div>
            </div>
          </td>
          <td class="showMoney" v-if="i % 2 == 0">
            <p style="cursor: pointer">
              {{ (v.plt.pltRefundDeductAmount / 100) | formatMoney }}
              <i class="el-icon-caret-bottom"></i>
            </p>
            <div
              class="show"
              :class="
                i >= tabData.length - 2 && tabData.length > 8
                  ? 'show-top'
                  : 'show-bottom'
              "
            >
              <div>退款笔数：{{ v.plt.pltRefundNum }}</div>
              <div>
                退款金额：{{ (v.plt.pltRefundAmount / 100) | formatMoney }}
              </div>
              <div>
                手续费回退：{{ (v.plt.pltRefundFee / 100) | formatMoney }}
              </div>
              <div>
                扣款金额：{{
                  (v.plt.pltRefundDeductAmount / 100) | formatMoney
                }}
              </div>
            </div>
          </td>
          <td class="showMoney" v-if="i % 2 == 0">
            <p>-</p>
          </td>

          <td v-if="i % 2 == 1">
            <p>分账商户</p>
          </td>
          <td class="showMoney" v-if="i % 2 == 1">
            <p style="cursor: pointer">
              {{ (v.allo.receiptAmount / 100) | formatMoney }}
              <i class="el-icon-caret-bottom"></i>
            </p>
            <div
              class="show"
              :class="
                i >= tabData.length - 2 && tabData.length > 8
                  ? 'show-top'
                  : 'show-bottom'
              "
            >
              <div>交易笔数：{{ v.allo.tradeNum }}</div>
              <div>
                分账金额：{{ (v.allo.tradeAmount / 100) | formatMoney }}
              </div>
              <div>手续费：{{ (v.allo.tradeFee / 100) | formatMoney }}</div>
              <div>
                入账金额：{{ (v.allo.receiptAmount / 100) | formatMoney }}
              </div>
            </div>
          </td>
          <td class="showMoney" v-if="i % 2 == 1">
            <p style="cursor: pointer">
              {{ (v.allo.refundDeductAmount / 100) | formatMoney }}
              <i class="el-icon-caret-bottom"></i>
            </p>
            <div
              class="show"
              :class="
                i >= tabData.length - 2 && tabData.length > 8
                  ? 'show-top'
                  : 'show-bottom'
              "
            >
              <div>退款笔数：{{ v.allo.refundNum }}</div>
              <div>
                退款金额：{{ (v.allo.refundAmount / 100) | formatMoney }}
              </div>
              <div>
                手续费回退：{{ (v.allo.refundFee / 100) | formatMoney }}
              </div>
              <div>
                扣款金额：{{ (v.allo.refundDeductAmount / 100) | formatMoney }}
              </div>
            </div>
          </td>
          <td class="showMoney" v-if="i % 2 == 1">
            <p style="cursor: pointer">
              {{ (v.allo.settDeductAmount / 100) | formatMoney }}
              <i class="el-icon-caret-bottom"></i>
            </p>
            <div
              class="show"
              :class="
                i >= tabData.length - 2 && tabData.length > 8
                  ? 'show-top'
                  : 'show-bottom'
              "
            >
              <div>出款笔数：{{ v.allo.settNum }}</div>
              <div>出款金额：{{ (v.allo.settAmount / 100) | formatMoney }}</div>
              <div>手续费：{{ (v.allo.settFee / 100) | formatMoney }}</div>
              <div>
                扣款金额：{{ (v.allo.settDeductAmount / 100) | formatMoney }}
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalNum"
    ></el-pagination>
    <el-dialog title="导出交易记录" :visible.sync="outPayShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title">商户名称</div>
          <el-select v-model="outputForm.mchId" placeholder="选择商户">
            <el-option
              v-for="v in mchDrop"
              :key="v.mchId"
              :label="v.mchName"
              :value="v.mchId"
            ></el-option>
          </el-select>
          <div class="overAccount_ul_title" v-show="selectTimeType == -1">
            <i>*</i>开始日期
          </div>
          <el-date-picker
            style="width: 200px; height: 36px"
            v-model="outputForm.startDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </el-date-picker>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>交易日期</div>
          <el-select
            v-model="selectTimeType"
            @change="timeChange"
            placeholder="选择交易日期"
          >
            <!-- <el-option label="今天" value="1"></el-option> -->
            <el-option label="昨天" value="2"></el-option>
            <el-option label="近7天" value="7"></el-option>
            <el-option label="其它" value="-1"></el-option>
          </el-select>
          <div class="overAccount_ul_title" v-show="selectTimeType == -1">
            <i>*</i>结束日期
          </div>
          <el-date-picker
            style="width: 200px; height: 36px"
            :picker-options="endOption"
            v-model="outputForm.endDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </el-date-picker>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="outPayShow = false">取 消</el-button>
        <el-button class="addBt" @click="output">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { formatDate } from "@/utils/common.js";
import { getAllocateData, exportAllocateSummary } from "@/api/data/bill.js";
import { merchantsDrop } from "@/api/common.js";
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    let that = this;
    return {
      query: {
        startDate: formatDate(+new Date() - 24 * 60 * 60 * 1000, "yyyy-MM-dd"),
        endDate: formatDate(+new Date() - 24 * 60 * 60 * 1000, "yyyy-MM-dd"),
        pageNo: 1,
        pageSize: 10,
        keyword: "",
      },
      tabData: [],
      totalNum: 0,
      statistics: {
        tradeNum: 0,
        tradeAmount: 0,
        tradeFee: 0,
        receiptAmount: 0,
        refundNum: 0,
        refundAmount: 0,
        refundFee: 0,
        refundDeductAmount: 0,
        settNum: 0,
        settAmount: 0,
        settFee: 0,
        settDeductAmount: 0,
        pltTradeNum: 0,
        pltTradeAmount: 0,
        pltTradeFee: 0,
        pltReceiptAmount: 0,
        pltRefundNum: 0,
        pltRefundAmount: 0,
        pltRefundFee: 0,
        pltRefundDeductAmount: 0,
      },
      outPayShow: false,
      outputForm: {
        startDate: "",
        endDate: "",
        mchId: "",
      },
      selectTimeType: "",
      endOption: {
        disabledDate(date) {
          return (
            date.getTime() <
              new Date(that.outputForm.startDate) - 24 * 60 * 60 * 1000 ||
            date.getTime() >
              new Date(
                +new Date(that.outputForm.startDate) + 30 * 24 * 60 * 60 * 1000
              )
          );
        },
      },
      mchDrop: [], //商户列表
    };
  },
  computed: {
    ...mapState({
      collectSubDetail: "bill_collectSubQuery",
    }),
  },
  created() {
    if (this.collectSubDetail) {
      this.query = this.collectSubDetail.query;
    }
    this.getSummaryData(false);
    this.getDrop();
    this.$enter(this.$route.path, this.getSummaryData);
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setCollectSubQuery(data);
  },
  methods: {
    ...mapMutations({
      setCollectSubQuery: "bill_setCollectSubhQuery",
    }),
    // 获取下拉
    getDrop() {
      merchantsDrop().then((res) => {
        if (res) {
          this.mchDrop = res.resultData;
        }
      });
    },
    getSummaryData(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
        this.query.keyword = this.query.keyword.trim();
      }
      this.query.startDate = this.query.startDate ? this.query.startDate : "";
      this.query.endDate = this.query.endDate ? this.query.endDate : "";
      this.query.keyword = this.query.keyword.trim();
      if (!this.query.startDate || !this.query.endDate) {
        this.$message.error("账单日期时间段必选");
        return;
      }
      getAllocateData(this.query).then((res) => {
        if (res) {
          res.resultData.bills.forEach((v) => {
            v.payActive = false;
            v.refundActive = false;
            v.tradeActive = false;
          });
          this.tabData = [];
          res.resultData.bills.forEach((obj) => {
            this.tabData.push(obj);
            this.tabData.push(obj);
          });
          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          this.statistics = res.resultData.statistics
            ? res.resultData.statistics
            : this.statistics;
          this.totalNum = res.resultData.totalNum;
        }
      });
    },
    // 导出展示
    outputShow() {
      this.outPayShow = true;
      this.outputForm = {
        startDate: "",
        endDate: "",
        mchId: "",
      };
      this.selectTimeType = "";
    },
    // 选择时间类型
    timeChange(value) {
      switch (value) {
        case "1":
          this.outputForm.startDate = formatDate(+new Date(), "yyyy-MM-dd");
          this.outputForm.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "2":
          this.outputForm.startDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.endDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          break;
        case "7":
          this.outputForm.startDate = formatDate(
            +new Date() - 6 * 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "-1":
          this.outputForm.startDate = "";
          this.outputForm.endDate = "";
          break;
      }
    },
    // 导出
    output() {
      if (!this.outputForm.startDate || !this.outputForm.endDate) {
        this.$message.error("导出时间段必选");
        return;
      }
      let data = this.outputForm;
      exportAllocateSummary(data).then((res) => {
        if (res) {
          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", "分账账单.csv");
          document.body.appendChild(link);
          link.click();
        }
      });
      this.outPayShow = false;
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getSummaryData();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getSummaryData(false);
    },
  },
};
</script>
<style scoped>
.con_from .lineP .itembtn{
  width:20%;
}
.con_from .lineP .item{
  margin-left: 2%;
}
.con_from .lineP .item:first-child{
  margin-left: 0%;
}
.showMoney {
  width: 160px;
  cursor: pointer;
  position: relative;
}
.showMoney:hover .show {
  display: block;
}
.showMoney .show {
  display: none;
  padding-top: 13px;
  padding-bottom: 4px;
  position: absolute;
  left: 50%;
  z-index: 99;
  min-width: 160px;
  background-color: #fff;
  transform: translateX(-50%);
  font-size: 14px;
  color: #666666;
  box-shadow: 0px 2px 10px 0px rgba(154, 172, 172, 0.2);
  border-radius: 4px;
}
.showMoney .show.show-bottom {
  top: 48px;
}
.showMoney .show.show-top {
  bottom: 48px;
}
.showMoney .show > div {
  white-space: nowrap;
  padding: 0 20px;
  margin-bottom: 8px;
}
.tab tr td p .el-icon-caret-bottom {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #48b8b6;
}

.con_from .desc {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-top: 24px;
  margin-bottom: 32px;
  background-color: rgba(255, 244, 224, 0.4);
  font-size: 14px;
  color: #ffb22b;
  padding: 0;
}
.con_from .desc i {
  font-size: 18px;
  color: #ffc258;
  margin-right: 16px;
}
.con_from .order {
  margin-bottom: 24px;
  color: #333333;
  font-size: 14px;
}
.con_from .order .labels {
  margin-left: 12px;
  margin-right: 40px;
  color: #48b8b6;
}
.con_from .order i {
  margin: 0 23px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.sort-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -7px;
}
.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}
.overAccount_ul_title i {
  color: #48b8b6;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>
